import { ChatIcon } from '@heroicons/react/outline'
import { Button } from 'antd'
import * as React from 'react'
import { openChat } from '../services/chat'

export default function HelpFooter () {
  return (
    <div className='mb-20 flex flex-col items-center'>
      <span className='text-xl font-bold'>Vous ne trouvez pas ce que vous cherchez ?</span>

      <Button onClick={openChat} size='large' className='rounded flex flex-row items-center mt-4'>
        <ChatIcon className='w-5 h-5 mr-4' />
        Discutez avec nous
      </Button>
    </div>
  )
}
