import * as React from 'react'

export default function HelpdeskArticles ({ articles }) {
  if (!articles) return null

  return (
    <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
      {articles.map(({ article: { document: { url, data: helpdeskArticle } } }, index) => (
        <div key={index} className="flex flex-col rounded-lg shadow overflow-hidden border border-gray-200">
          <div className="flex-1 bg-white p-4 pt-4 flex flex-col justify-between">
            <div className="flex-1">
              <a href={url} className="block pb-2">
                <p className="mb-1 text-lg font-semibold text-gray-900">{helpdeskArticle.title}</p>
                <p className="mt-0 mb-2 text-base text-gray-500">{helpdeskArticle.description}</p>
              </a>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a href={helpdeskArticle.author.document.data.link.url}>
                  <img className="h-10 w-10 transform scale-100 hover:scale-125 rounded-full" src={helpdeskArticle.author.document.data.avatar.thumbnails.small.url} alt={helpdeskArticle.author.document.data.avatar.alt} />
                </a>
              </div>
              <div className="ml-3">
                <span className="text-sm font-medium text-gray-900">
                  <a href={helpdeskArticle.author.document.data.link.url} className="hover:underline">
                    {helpdeskArticle.author.document.data.first_name} {helpdeskArticle.author.document.data.last_name}
                  </a>
                </span>
                <div className="flex space-x-1 text-sm text-gray-500">
                  <time dateTime={helpdeskArticle.datetime}>mis à jour {helpdeskArticle.date}</time>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
