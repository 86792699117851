import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { SupportIcon } from '@heroicons/react/outline'
import * as HIcons from '@heroicons/react/outline'
import * as HIconsSolid from '@heroicons/react/solid'
import { camelcase, upperFirst } from 'lodash'

import Head from '../components/head'
import HelpdeskArticles from '../components/helpdesk-articles'
import Breadcrumb from '../components/breadcrumb'
import HelpFooter from '../components/help-footer'

export const query = graphql`
  query ($url: String!) {
    prismicHelpdeskCategory(url: { eq: $url }) {
      uid
      url
      data {
        color
        name
        description
        icon
        sub_categories {
          sub_category {
            document {
              ... on PrismicHelpdeskSubcategory {
                id
                data {
                  name
                  articles {
                    article {
                      document {
                        ... on PrismicHelpdeskArticle {
                          _previewable
                          id
                          uid
                          url
                          data {
                            title
                            description
                            date(fromNow: true, locale: "fr-FR")
                            category {
                              uid
                            }
                            author {
                              document {
                                ... on PrismicAuthor {
                                  id
                                  data {
                                    avatar {
                                      alt
                                      thumbnails {
                                        small {
                                          url
                                        }
                                      }
                                    }
                                    first_name
                                    last_name
                                    link {
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const HelpdeskCategory = ({ data }) => {
  if (!data) return null

  const category = data.prismicHelpdeskCategory?.data
  const subcategories = category?.sub_categories

  const iconName = `${upperFirst(camelcase(category.icon))}Icon`
  const HeroIcon = HIcons[iconName]
  const HeroIconSolid = HIconsSolid[iconName]

  return (
    <Layout>
      <Head
        title={category?.name}
        description={category?.description}
      />

      <div className="blog-content-container space-around-row relative bg-gray-50 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8 mb-12">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>

        <div className="relative max-w-7xl mx-auto">
          <Breadcrumb
            links={[
              { url: '/help/', icon: <SupportIcon className='w-4 h-4 mr-2' />, label: 'Support & Guide' },
              { url: data.prismicHelpdeskCategory.url, icon: <HeroIcon className='w-4 h-4 mr-2' />, label: category.name, current: true }
            ]}
          />

          <div className="flex flex-row items-center mt-4">
            <div><HeroIconSolid className='w-12 h-12 mr-2' /></div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl rc-highlight-text mb-0" style={{ '--highlight-color': category?.color }}>{category?.name}</h2>
          </div>

          <p className="ml-0 text-justify mt-2.5 max-w-2xl text-lg text-gray-500 mb-0">{category?.description}</p>

          {subcategories.map(({ sub_category: { document: subcat } }, i) => {
            return (
              <div key={i} className='mt-8'>
                <div className='text-lg font-bold mb-4'>{subcat?.data?.name}</div>

                <HelpdeskArticles articles={subcat?.data?.articles} />
              </div>
            )
          })}
        </div>
      </div>

      <HelpFooter />
    </Layout>
  )
}

export default HelpdeskCategory
