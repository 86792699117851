import * as React from 'react'
import cx from 'classnames'

export default function Breadcrumb ({ links }) {
  if (!links) return null

  return (
    <nav aria-label="Breadcrumb" className="flex flex-col lg:flex-row items-start lg:items-center -mt-4">
      {links.map((link, index) => {
        return (
          <div
            key={index}
            className={cx(
              'flex flex-row items-center text-xs lg:text-sm mb-2',
              link.current ? 'text-gray-500 hover:text-gray-700' : ''
            )}
          >
            {index !== 0 && (<div className="font-thin text-gray-300 mr-2 lg:mr-4 ml-0 lg:ml-4">/</div>)}

            <div className='text-gray-500'>{link.icon}</div>

            <a
              href={link.url}
              aria-current={link.current ? 'page' : undefined}
            >
              {link.label}
            </a>
          </div>
        )
      })}
    </nav>
  )
}
